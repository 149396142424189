<template>
  <loading :loader="type" :active.sync="isLoading" :can-cancel="false"
    :is-full-page="false">
    <template #before>
      <div>{{ $t('overlay.loading') }}</div>
    </template>
  </loading>
</template>

<script>
import Loading from 'vue-loading-overlay'

export default {
  props: {
    loading: {
      require: true,
      type: Boolean
    },
    type: {
      type: String,
      default: 'bars'
    }
  },
  components: {
    Loading
  },
  computed: {
    isLoading() {
      return this.loading
    }
  }
}
</script>
