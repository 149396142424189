<template>
  <modal :height="height" :width="width" :minHeight="minHeight"
    :maxHeight="maxHeight" :minWidth="minWidth" :maxWidth="maxWidth"
    :name="name" :scrollable="scrollable" :adaptive="true" v-bind="$attrs"
    v-on="{ ...$listeners }">
    <div class="modal-container" :class="[{ border }, customClass]">
      <slot name="header">
        <hcc-modal-header v-if="title" :title="title" />
      </slot>
      <hcc-modal-body :class="[customClassBody]">
        <slot />
      </hcc-modal-body>
    </div>
    <slot name="footer">
      <hcc-modal-footer :disabled="disableConfirmButton"
        :handleConfirm="handleConfirm" :handleCancel="handleCancel"
        :buttons="buttons" />
    </slot>
  </modal>
</template>

<script>
import HccModalHeader from './HccModalHeader.vue'
import HccModalBody from './HccModalBody.vue'
import HccModalFooter from './HccModalFooter.vue'

export default {
  components: {
    HccModalHeader,
    HccModalBody,
    HccModalFooter
  },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    border: {
      type: Boolean,
      default: true
    },
    customClass: {
      type: [String, Object, Array]
    },
    customClassBody: {
      type: [String, Object, Array]
    },
    buttons: {
      type: Array,
      default: () => ['cancel', 'confirm']
    },
    width: {
      type: [Number, String],
      default: 500
    },
    minWidth: {
      type: Number
    },
    maxWidth: {
      type: Number,
      default: 1200
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    minHeight: {
      type: Number
    },
    maxHeight: {
      type: Number
    },
    disableConfirmButton: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    hideOnConfirm: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm')
      if (this.hideOnConfirm) {
        this.$modal.hide(this.$props.name)
      }
    },
    handleCancel() {
      this.$emit('cancel')
      this.$modal.hide(this.$props.name)
    }
  }
}
</script>

<style lang="scss">
@import "~styles/components/modals.scss";
</style>
