<template>
  <div class="modal__header">
    <span class="modal__header--title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
@import "~styles/components/modals.scss";
</style>
