<template>
  <div class="modal__buttons">
    <hcc-button v-if="buttons.includes('cancel')" @click="handleCancel"
      size="sm" variant="outline" color="muted">{{ modal.cancel }}
    </hcc-button>
    <hcc-button v-if="buttons.includes('confirm')" :disabled="disabled"
      @click="handleConfirm" size="sm" class="modal__buttons--button"
      :color="getButtonColor">{{ modal.confirm }}
    </hcc-button>
    <hcc-button v-if="buttons.includes('exit')" @click="handleCancel" size="sm"
      variant="outline" color="muted">{{ modal.exit }}
    </hcc-button>
  </div>
</template>

<script>
import HccButton from '../HccButton.vue'

export default {
  components: {
    HccButton
  },
  props: {
    variant: {
      type: String,
      default: 'alert'
    },
    buttons: {
      type: Array,
      default: () => ['cancel', 'confirm']
    },
    handleConfirm: {
      type: Function
    },
    handleCancel: {
      type: Function
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    getButtonColor() {
      let color = 'sellia-secondary'

      if (this.variant === 'error') {
        color = 'alert'
      } else if (this.variant === 'info') {
        color = 'sellia-primary'
      }

      return color
    },
    modal() {
      return this.$t('modal')
    }
  }
}
</script>

<style lang="scss">
@import "~styles/components/modals.scss";
</style>
