<template>
  <button class="button-icon"
    :class="[{ muted: muted && !round, round }, color, size, customClass]"
    :disabled="disabled" ref="clickBtn" v-on:click="animateRipple">
    <slot></slot>
    <transition-group>
      <span class="icon-button-effect" :ref="'icon-button-effect' + i"
        :key="'icon-button-effect' + i" v-for="(val, i) in activeRipples"
        :style="{ top: val.y + 'px', left: val.x + 'px' }"
        v-on:animationend="rippleEnd(i)">
      </span>
    </transition-group>
  </button>
</template>

<script>
export default {
  name: 'HccButtonIcon',
  props: {
    color: {
      type: String,
      default: 'sellia-secondary'
    },
    muted: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'sm'
    },
    customClass: {
      type: [String, Object, Array]
    }
  },
  data() {
    return {
      ripples: []
    }
  },
  computed: {
    activeRipples() {
      return this.ripples.filter((r) => r.show)
    }
  },
  methods: {
    animateRipple(e) {
      const el = this.$refs.clickBtn
      const pos = el.getBoundingClientRect()
      this.ripples.push({
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true
      })
      this.$emit('click', e)
    },
    rippleEnd(i) {
      this.ripples[i].show = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "~styles/components/buttons.scss";
@import "~styles/components/buttons-icons.scss";
</style>
