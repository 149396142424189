<template>
  <vue-good-table v-bind="$attrs" v-on="$listeners" :rows="rows"
    :columns="fullColumns" styleClass="vgt-table"
    :search-options="enableSearchTable()"
    :pagination-options="{ enabled: enablePagination(), perPage: rowsPerPage }">
    <div slot="emptystate" class="empty-rows">
      <div class="empty-rows__container">
        <paper-icon class="empty-rows__icon" />
      </div>
      <span class="empty-rows__text">{{ $t('table.empty') }}</span>
    </div>
    <div slot="table-actions" class="table-actions"
      v-if="title || $listeners.search || $listeners.add">
      <span v-if="title" :title="title" class="table__title">{{ title }}</span>
      <div class="table-actions__items"
        v-if="$listeners.search || $listeners.add">
        <hcc-input :placeholder="searchText" v-model="searchValue"
          v-if="$listeners.search" @keyup.enter="$emit('search', searchValue)">
          <template #icon>
            <search-icon />
          </template>
        </hcc-input>
        <hcc-button class="table__button" v-if="$listeners.add"
          @click="$emit('add')">
          <plus-circle-icon class="add-button" />
          {{ $t("table.add") }}
        </hcc-button>
      </div>
    </div>
    <template slot="table-row" slot-scope="props">
      <slot name="table-row" v-bind="props">
        <span v-if="props.column != 'actions'">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </slot>
      <span v-if="props.column.field == 'actions'">
        <div v-if="showActions($listeners)">
          <div v-if="!props.row.editable">
            <hcc-button-icon v-if="$listeners.edit && !$listeners.editSubgroup"
              v-tooltip="$t('points.edit')" @click="$emit('edit', props.row)">
              <pencil-icon />
            </hcc-button-icon>
            <hcc-button-icon v-else-if="$listeners.editSubgroup"
              @click="toggleEditRow(props.row)">
              <pencil-icon />
            </hcc-button-icon>
            <hcc-button-icon
              v-if="$listeners.delete && !$listeners.deleteSubgroup"
              v-tooltip="$t('points.delete')"
              @click="$emit('delete', props.row)">
              <delete-icon />
            </hcc-button-icon>
            <hcc-button-icon v-else-if="$listeners.deleteSubgroup"
              @click="$emit('deleteSubgroup', props.row)">
              <delete-icon />
            </hcc-button-icon>
          </div>
          <div v-else>
            <hcc-button-icon :muted="false" color="alert-green"
              @click="$emit('editSubgroup', props.row)">
              <check-icon />
            </hcc-button-icon>
            <hcc-button-icon :muted="false" color="alert"
              @click="toggleEditRow(props.row)">
              <window-close-icon />
            </hcc-button-icon>
          </div>
        </div>
      </span>
    </template>
    <template slot="table-header-row" slot-scope="props">
      <span v-if="props.column.field == 'actions'">
        <hcc-button-icon v-if="$listeners.edit"
          @click="$emit('edit', props.row)">
          <pencil-icon />
        </hcc-button-icon>
        <hcc-button-icon v-if="$listeners.delete"
          @click="$emit('delete', props.row)">
          <delete-icon />
        </hcc-button-icon>
        <hcc-button-icon v-if="$listeners.addSubgroup"
          @click="$emit('addSubgroup', props.row)">
          <plus-circle-icon />
        </hcc-button-icon>
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <template slot="pagination-bottom" slot-scope="props">
      <hcc-pagination :total="props.total" :rowsPerPage="rowsPerPage"
        :actualPage="actualPage" :pageChanged="props.pageChanged"
        @page-change="actualPage = $event" />
    </template>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import SearchIcon from '@/assets/icons/search.svg'
import PlusCircleIcon from '@/assets/icons/plus-circle.svg'
import DeleteIcon from '@/assets/icons/delete-icon.svg'
import PaperIcon from '@/assets/icons/paper.svg'
import PencilIcon from '@/assets/icons/pencil.svg'
import HccInput from './HccInput.vue'
import HccButton from './HccButton.vue'
import HccButtonIcon from './HccButtonIcon.vue'
import HccPagination from './HccPagination.vue'

export default {
  components: {
    VueGoodTable,
    HccInput,
    HccButton,
    HccButtonIcon,
    HccPagination,
    SearchIcon,
    DeleteIcon,
    PaperIcon,
    PencilIcon,
    PlusCircleIcon
  },
  props: {
    title: {
      type: String
    },
    initialPage: {
      type: Number,
      default: 1
    },
    columns: {
      type: Array
    },
    pagination: {
      type: Boolean,
      default: true
    },
    rows: {
      type: Array,
      default: () => []
    },
    rowsPerPage: {
      type: Number,
      default: 5
    },
    enableSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: '',
      actualPage: this.initialPage,
      editableRow: null,
      searchText: this.$t('table.search')
    }
  },
  computed: {
    fullColumns() {
      return this.showActions(this.$listeners) ? [...this.columns, {
        label: this.$t('tickets.actions'),
        field: 'actions',
        sortable: false,
        thClass: 'custom-th-class',
        tdClass: 'custom-td-class'
      }] : this.columns
    }
  },
  watch: {
    rows: {
      deep: true,
      handler(newRows) {
        this.editableRow = newRows
          .every((row) => !row.editable && !!row.children && row.children
            .every((r) => !r.editable)) ? null : this.editableRow
      }
    }
  },
  methods: {
    showActions(listeners) {
      const eventListeners = Object.keys(listeners).filter(
        (listener) => (listener !== 'add' && listener !== 'search')
      ).length

      return eventListeners
    },
    enablePagination() {
      return this.rows.length === 0 ? false : !!this.$props.pagination
    },
    enableSearchTable() {
      return this.enableSearch ? { enabled: true, externalQuery: this.searchValue }
        : { enabled: false }
    },
    toggleEditRow(row) {
      if (row.editable) {
        Object.assign(row, this.editableRow)
        // eslint-disable-next-line no-param-reassign
        row.editable = false
        this.editableRow = null
      } else if (this.editableRow === null) {
        // eslint-disable-next-line no-param-reassign
        row.editable = true
        this.editableRow = JSON.parse(JSON.stringify(row))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.add-button {
  transform: scale(0.6)
}
</style>
